import React from 'react';
import ReactDOM from 'react-dom';

import "./styles.scss";

import {createMuiTheme, CssBaseline, ThemeProvider} from "@material-ui/core";

import {Loading} from "./components/Loading";


const App = React.lazy(() => import('./App').then(({App}) => ({default: App})));

const theme = createMuiTheme({
    palette: {
        background: {
            default: "#FAFAFC",
        },
    },
    shadows: [
        "0 0 1px rgb(0 40 117 / 8%)",
        "0 0 2px rgb(0 40 117 / 8%)",
        "0 0 3px rgb(0 40 117 / 8%)",
        "0 0 4px rgb(0 40 117 / 8%)",
        "0 0 5px rgb(0 40 117 / 8%)",
        "0 0 6px rgb(0 40 117 / 8%)",
        "0 0 7px rgb(0 40 117 / 8%)",
        "0 0 8px rgb(0 40 117 / 8%)",
        "0 0 9px rgb(0 40 117 / 8%)",
        "0 0 10px rgb(0 40 117 / 8%)",
        "0 0 11px rgb(0 40 117 / 8%)",
        "0 0 12px rgb(0 40 117 / 8%)",
        "0 0 13px rgb(0 40 117 / 8%)",
        "0 0 14px rgb(0 40 117 / 8%)",
        "0 0 15px rgb(0 40 117 / 8%)",
        "0 0 16px rgb(0 40 117 / 8%)",
        "0 0 17px rgb(0 40 117 / 8%)",
        "0 0 18px rgb(0 40 117 / 8%)",
        "0 0 19px rgb(0 40 117 / 8%)",
        "0 0 20px rgb(0 40 117 / 8%)",
        "0 0 21px rgb(0 40 117 / 8%)",
        "0 0 22px rgb(0 40 117 / 8%)",
        "0 0 23px rgb(0 40 117 / 8%)",
        "0 0 24px rgb(0 40 117 / 8%)",
        "0 0 25px rgb(0 40 117 / 8%)",
    ],
    overrides: {
        MuiInputBase: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '99999s',
                    transitionProperty: 'background-color, color',
                },
            },
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <React.Suspense fallback={<Loading/>}>
                <App/>
            </React.Suspense>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('react-root')
);